export const data = [
    { date: new Date("2022-05-1"), cost: 126, goal: 191 },
    { date: new Date("2022-05-2"), cost: 135, goal: 108 },
    { date: new Date("2022-05-3"), cost: 103, goal: 182 },
    { date: new Date("2022-05-4"), cost: 110, goal: 131 },
    { date: new Date("2022-05-5"), cost: 226, goal: 180 },
    { date: new Date("2022-05-6"), cost: 134, goal: 248 },
    { date: new Date("2022-05-7"), cost: 227, goal: 248 },
    { date: new Date("2022-05-8"), cost: 229, goal: 122 },
    { date: new Date("2022-05-9"), cost: 183, goal: 204 },
    { date: new Date("2022-05-10"), cost: 240, goal: 118 },
    { date: new Date("2022-05-11"), cost: 195, goal: 218 },
    { date: new Date("2022-05-12"), cost: 188, goal: 222 },
    { date: new Date("2022-05-13"), cost: 210, goal: 106 },
    { date: new Date("2022-05-14"), cost: 197, goal: 221 },
    { date: new Date("2022-05-15"), cost: 145, goal: 216 },
    { date: new Date("2022-05-16"), cost: 114, goal: 133 },
    { date: new Date("2022-05-17"), cost: 233, goal: 197 },
    { date: new Date("2022-05-18"), cost: 195, goal: 250 },
    { date: new Date("2022-05-19"), cost: 157, goal: 248 },
    { date: new Date("2022-05-20"), cost: 157, goal: 114 },
    { date: new Date("2022-05-21"), cost: 135, goal: 220 },
    { date: new Date("2022-05-22"), cost: 203, goal: 172 },
    { date: new Date("2022-05-23"), cost: 227, goal: 157 },
    { date: new Date("2022-05-24"), cost: 180, goal: 139 },
    { date: new Date("2022-05-25"), cost: 146, goal: 152 },
    { date: new Date("2022-05-26"), cost: 185, goal: 106 },
    { date: new Date("2022-05-27"), cost: 183, goal: 107 },
    { date: new Date("2022-05-28"), cost: 136, goal: 180 },
    { date: new Date("2022-05-29"), cost: 202, goal: 235 },
    { date: new Date("2022-05-30"), cost: 118, goal: 213 },
];

export const data2 = [
    { date: new Date("2022-05-1"), cost: 222, goal: 225 },
    { date: new Date("2022-05-2"), cost: 211, goal: 217 },
    { date: new Date("2022-05-3"), cost: 200, goal: 216 },
    { date: new Date("2022-05-4"), cost: 237, goal: 197 },
    { date: new Date("2022-05-5"), cost: 238, goal: 280 },
    { date: new Date("2022-05-6"), cost: 214, goal: 182 },
    { date: new Date("2022-05-7"), cost: 207, goal: 184 },
    { date: new Date("2022-05-8"), cost: 201, goal: 188 },
    { date: new Date("2022-05-9"), cost: 227, goal: 219 },
    { date: new Date("2022-05-10"), cost: 213, goal: 193 },
    { date: new Date("2022-05-11"), cost: 224, goal: 214 },
    { date: new Date("2022-05-12"), cost: 224, goal: 249 },
    { date: new Date("2022-05-13"), cost: 247, goal: 219 },
    { date: new Date("2022-05-14"), cost: 229, goal: 216 },
    { date: new Date("2022-05-15"), cost: 220, goal: 245 },
    { date: new Date("2022-05-16"), cost: 230, goal: 227 },
    { date: new Date("2022-05-17"), cost: 220, goal: 236 },
    { date: new Date("2022-05-18"), cost: 208, goal: 254 },
    { date: new Date("2022-05-19"), cost: 206, goal: 245 },
    { date: new Date("2022-05-20"), cost: 220, goal: 263 },
    { date: new Date("2022-05-21"), cost: 249, goal: 206 },
    { date: new Date("2022-05-22"), cost: 207, goal: 260 },
    { date: new Date("2022-05-23"), cost: 241, goal: 180 },
    { date: new Date("2022-05-24"), cost: 225, goal: 187 },
    { date: new Date("2022-05-25"), cost: 221, goal: 185 },
    { date: new Date("2022-05-26"), cost: 228, goal: 249 },
    { date: new Date("2022-05-27"), cost: 237, goal: 181 },
    { date: new Date("2022-05-28"), cost: 201, goal: 204 },
    { date: new Date("2022-05-29"), cost: 233, goal: 199 },
    { date: new Date("2022-05-30"), cost: 212, goal: 222 },
];

export const data3 = [
    {
        date: new Date("2022-5-1"),
        production_cost: 612,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 678,
        fte: 102,
        goal: 95,
    },
    {
        date: new Date("2022-5-2"),
        production_cost: 601,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 850,
        fte: 97,
        goal: 95,
    },
    {
        date: new Date("2022-5-3"),
        production_cost: 594,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 817,
        fte: 101,
        goal: 95,
    },
    {
        date: new Date("2022-5-4"),
        production_cost: 619,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 858,
        fte: 101,
        goal: 95,
    },
    {
        date: new Date("2022-5-5"),
        production_cost: 594,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 893,
        fte: 103,
        goal: 95,
    },
    {
        date: new Date("2022-5-6"),
        production_cost: 625,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 658,
        fte: 100,
        goal: 95,
    },
    {
        date: new Date("2022-5-7"),
        production_cost: 615,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 667,
        fte: 97,
        goal: 95,
    },
    {
        date: new Date("2022-5-8"),
        production_cost: 586,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 842,
        fte: 102,
        goal: 95,
    },
    {
        date: new Date("2022-5-9"),
        production_cost: 602,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 674,
        fte: 106,
        goal: 95,
    },
    {
        date: new Date("2022-5-10"),
        production_cost: 611,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 871,
        fte: 103,
        goal: 95,
    },
    {
        date: new Date("2022-5-11"),
        production_cost: 631,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 668,
        fte: 97,
        goal: 95,
    },
    {
        date: new Date("2022-5-12"),
        production_cost: 610,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 830,
        fte: 101,
        goal: 95,
    },
    {
        date: new Date("2022-5-13"),
        production_cost: 621,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 653,
        fte: 100,
        goal: 95,
    },
    {
        date: new Date("2022-5-14"),
        production_cost: 610,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 838,
        fte: 105,
        goal: 95,
    },
    {
        date: new Date("2022-5-15"),
        production_cost: 621,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 805,
        fte: 105,
        goal: 95,
    },
    {
        date: new Date("2022-5-16"),
        production_cost: 612,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 623,
        fte: 101,
        goal: 95,
    },
    {
        date: new Date("2022-5-17"),
        production_cost: 605,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 672,
        fte: 106,
        goal: 95,
    },
    {
        date: new Date("2022-5-18"),
        production_cost: 593,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 830,
        fte: 105,
        goal: 95,
    },
    {
        date: new Date("2022-5-19"),
        production_cost: 631,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 817,
        fte: 101,
        goal: 95,
    },
    {
        date: new Date("2022-5-20"),
        production_cost: 630,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 891,
        fte: 104,
        goal: 95,
    },
    {
        date: new Date("2022-5-21"),
        production_cost: 592,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 701,
        fte: 99,
        goal: 95,
    },
    {
        date: new Date("2022-5-22"),
        production_cost: 619,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 612,
        fte: 96,
        goal: 95,
    },
    {
        date: new Date("2022-5-23"),
        production_cost: 601,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 837,
        fte: 104,
        goal: 95,
    },
    {
        date: new Date("2022-5-24"),
        production_cost: 586,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 814,
        fte: 103,
        goal: 95,
    },
    {
        date: new Date("2022-5-25"),
        production_cost: 601,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 873,
        fte: 100,
        goal: 95,
    },
    {
        date: new Date("2022-5-26"),
        production_cost: 587,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 752,
        fte: 99,
        goal: 95,
    },
    {
        date: new Date("2022-5-27"),
        production_cost: 631,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 715,
        fte: 96,
        goal: 95,
    },
    {
        date: new Date("2022-5-28"),
        production_cost: 624,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 648,
        fte: 99,
        goal: 95,
    },
    {
        date: new Date("2022-5-29"),
        production_cost: 587,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 627,
        fte: 97,
        goal: 95,
    },
    {
        date: new Date("2022-5-30"),
        production_cost: 600,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 642,
        fte: 100,
        goal: 95,
    },
    {
        date: new Date("2022-5-31"),
        production_cost: 619,
        production_goal: 524,
        quantity_goal: 750,
        quantity_actual: 896,
        fte: 102,
        goal: 95,
    },
];

export const work_data = [
    {
        date: "2022-04-30T18:30:00.000Z",
        team0: { actual: 366, prod: 321 },
        team1: { actual: 370, prod: 273 },
        team2: { actual: 352, prod: 400 },
        team3: { actual: 429, prod: 343 },
        team4: { actual: 381, prod: 258 },
        team5: { actual: 349, prod: 297 },
        team6: { actual: 370, prod: 263 },
        team7: { actual: 296, prod: 301 },
        team8: { actual: 421, prod: 268 },
        team9: { actual: 398, prod: 278 },
    },
    {
        date: "2022-05-01T18:30:00.000Z",
        team0: { actual: 442, prod: 356 },
        team1: { actual: 353, prod: 352 },
        team2: { actual: 373, prod: 386 },
        team3: { actual: 298, prod: 342 },
        team4: { actual: 330, prod: 425 },
        team5: { actual: 399, prod: 265 },
        team6: { actual: 320, prod: 401 },
        team7: { actual: 299, prod: 263 },
        team8: { actual: 358, prod: 307 },
        team9: { actual: 295, prod: 372 },
    },
    {
        date: "2022-05-02T18:30:00.000Z",
        team0: { actual: 387, prod: 298 },
        team1: { actual: 395, prod: 379 },
        team2: { actual: 379, prod: 387 },
        team3: { actual: 384, prod: 312 },
        team4: { actual: 297, prod: 354 },
        team5: { actual: 301, prod: 258 },
        team6: { actual: 353, prod: 412 },
        team7: { actual: 335, prod: 263 },
        team8: { actual: 398, prod: 287 },
        team9: { actual: 354, prod: 287 },
    },
    {
        date: "2022-05-03T18:30:00.000Z",
        team0: { actual: 336, prod: 269 },
        team1: { actual: 440, prod: 401 },
        team2: { actual: 339, prod: 288 },
        team3: { actual: 385, prod: 268 },
        team4: { actual: 296, prod: 363 },
        team5: { actual: 325, prod: 263 },
        team6: { actual: 346, prod: 280 },
        team7: { actual: 354, prod: 376 },
        team8: { actual: 300, prod: 323 },
        team9: { actual: 374, prod: 277 },
    },
    {
        date: "2022-05-04T18:30:00.000Z",
        team0: { actual: 355, prod: 264 },
        team1: { actual: 374, prod: 257 },
        team2: { actual: 423, prod: 268 },
        team3: { actual: 328, prod: 411 },
        team4: { actual: 438, prod: 323 },
        team5: { actual: 344, prod: 330 },
        team6: { actual: 315, prod: 397 },
        team7: { actual: 309, prod: 266 },
        team8: { actual: 330, prod: 306 },
        team9: { actual: 330, prod: 394 },
    },
    {
        date: "2022-05-05T18:30:00.000Z",
        team0: { actual: 347, prod: 390 },
        team1: { actual: 365, prod: 399 },
        team2: { actual: 362, prod: 391 },
        team3: { actual: 438, prod: 310 },
        team4: { actual: 420, prod: 422 },
        team5: { actual: 375, prod: 329 },
        team6: { actual: 409, prod: 406 },
        team7: { actual: 314, prod: 334 },
        team8: { actual: 311, prod: 385 },
        team9: { actual: 319, prod: 372 },
    },
    {
        date: "2022-05-06T18:30:00.000Z",
        team0: { actual: 391, prod: 340 },
        team1: { actual: 294, prod: 420 },
        team2: { actual: 396, prod: 317 },
        team3: { actual: 334, prod: 264 },
        team4: { actual: 429, prod: 397 },
        team5: { actual: 334, prod: 315 },
        team6: { actual: 334, prod: 374 },
        team7: { actual: 380, prod: 298 },
        team8: { actual: 365, prod: 385 },
        team9: { actual: 292, prod: 332 },
    },
    {
        date: "2022-05-07T18:30:00.000Z",
        team0: { actual: 398, prod: 326 },
        team1: { actual: 400, prod: 399 },
        team2: { actual: 339, prod: 370 },
        team3: { actual: 397, prod: 263 },
        team4: { actual: 392, prod: 354 },
        team5: { actual: 349, prod: 289 },
        team6: { actual: 374, prod: 383 },
        team7: { actual: 321, prod: 353 },
        team8: { actual: 398, prod: 365 },
        team9: { actual: 301, prod: 266 },
    },
    {
        date: "2022-05-08T18:30:00.000Z",
        team0: { actual: 346, prod: 377 },
        team1: { actual: 322, prod: 366 },
        team2: { actual: 323, prod: 418 },
        team3: { actual: 401, prod: 357 },
        team4: { actual: 400, prod: 264 },
        team5: { actual: 420, prod: 416 },
        team6: { actual: 432, prod: 361 },
        team7: { actual: 390, prod: 381 },
        team8: { actual: 292, prod: 292 },
        team9: { actual: 422, prod: 394 },
    },
    {
        date: "2022-05-09T18:30:00.000Z",
        team0: { actual: 430, prod: 313 },
        team1: { actual: 395, prod: 268 },
        team2: { actual: 378, prod: 259 },
        team3: { actual: 297, prod: 331 },
        team4: { actual: 396, prod: 266 },
        team5: { actual: 372, prod: 278 },
        team6: { actual: 331, prod: 285 },
        team7: { actual: 360, prod: 337 },
        team8: { actual: 298, prod: 303 },
        team9: { actual: 410, prod: 301 },
    },
    {
        date: "2022-05-10T18:30:00.000Z",
        team0: { actual: 356, prod: 383 },
        team1: { actual: 372, prod: 401 },
        team2: { actual: 414, prod: 424 },
        team3: { actual: 346, prod: 297 },
        team4: { actual: 421, prod: 327 },
        team5: { actual: 405, prod: 405 },
        team6: { actual: 330, prod: 393 },
        team7: { actual: 339, prod: 338 },
        team8: { actual: 435, prod: 411 },
        team9: { actual: 416, prod: 395 },
    },
    {
        date: "2022-05-11T18:30:00.000Z",
        team0: { actual: 329, prod: 413 },
        team1: { actual: 350, prod: 299 },
        team2: { actual: 419, prod: 402 },
        team3: { actual: 336, prod: 290 },
        team4: { actual: 439, prod: 386 },
        team5: { actual: 393, prod: 399 },
        team6: { actual: 297, prod: 271 },
        team7: { actual: 374, prod: 399 },
        team8: { actual: 322, prod: 372 },
        team9: { actual: 332, prod: 358 },
    },
    {
        date: "2022-05-12T18:30:00.000Z",
        team0: { actual: 297, prod: 325 },
        team1: { actual: 411, prod: 335 },
        team2: { actual: 393, prod: 274 },
        team3: { actual: 338, prod: 297 },
        team4: { actual: 427, prod: 389 },
        team5: { actual: 426, prod: 342 },
        team6: { actual: 442, prod: 353 },
        team7: { actual: 380, prod: 390 },
        team8: { actual: 330, prod: 311 },
        team9: { actual: 304, prod: 424 },
    },
    {
        date: "2022-05-13T18:30:00.000Z",
        team0: { actual: 424, prod: 358 },
        team1: { actual: 392, prod: 403 },
        team2: { actual: 405, prod: 340 },
        team3: { actual: 322, prod: 413 },
        team4: { actual: 439, prod: 424 },
        team5: { actual: 340, prod: 312 },
        team6: { actual: 400, prod: 272 },
        team7: { actual: 348, prod: 408 },
        team8: { actual: 432, prod: 318 },
        team9: { actual: 419, prod: 296 },
    },
    {
        date: "2022-05-14T18:30:00.000Z",
        team0: { actual: 295, prod: 291 },
        team1: { actual: 375, prod: 334 },
        team2: { actual: 426, prod: 330 },
        team3: { actual: 347, prod: 292 },
        team4: { actual: 363, prod: 389 },
        team5: { actual: 414, prod: 327 },
        team6: { actual: 422, prod: 365 },
        team7: { actual: 358, prod: 291 },
        team8: { actual: 411, prod: 343 },
        team9: { actual: 295, prod: 320 },
    },
    {
        date: "2022-05-15T18:30:00.000Z",
        team0: { actual: 380, prod: 269 },
        team1: { actual: 318, prod: 382 },
        team2: { actual: 422, prod: 338 },
        team3: { actual: 392, prod: 299 },
        team4: { actual: 359, prod: 423 },
        team5: { actual: 400, prod: 307 },
        team6: { actual: 303, prod: 261 },
        team7: { actual: 297, prod: 394 },
        team8: { actual: 327, prod: 415 },
        team9: { actual: 346, prod: 303 },
    },
    {
        date: "2022-05-16T18:30:00.000Z",
        team0: { actual: 379, prod: 384 },
        team1: { actual: 350, prod: 343 },
        team2: { actual: 390, prod: 318 },
        team3: { actual: 428, prod: 424 },
        team4: { actual: 420, prod: 330 },
        team5: { actual: 399, prod: 328 },
        team6: { actual: 422, prod: 264 },
        team7: { actual: 371, prod: 394 },
        team8: { actual: 414, prod: 310 },
        team9: { actual: 414, prod: 375 },
    },
    {
        date: "2022-05-17T18:30:00.000Z",
        team0: { actual: 394, prod: 337 },
        team1: { actual: 427, prod: 342 },
        team2: { actual: 407, prod: 323 },
        team3: { actual: 315, prod: 378 },
        team4: { actual: 318, prod: 407 },
        team5: { actual: 380, prod: 267 },
        team6: { actual: 332, prod: 420 },
        team7: { actual: 306, prod: 304 },
        team8: { actual: 376, prod: 424 },
        team9: { actual: 373, prod: 352 },
    },
    {
        date: "2022-05-18T18:30:00.000Z",
        team0: { actual: 393, prod: 402 },
        team1: { actual: 325, prod: 305 },
        team2: { actual: 359, prod: 424 },
        team3: { actual: 420, prod: 359 },
        team4: { actual: 328, prod: 279 },
        team5: { actual: 343, prod: 373 },
        team6: { actual: 320, prod: 378 },
        team7: { actual: 305, prod: 307 },
        team8: { actual: 435, prod: 386 },
        team9: { actual: 442, prod: 312 },
    },
    {
        date: "2022-05-19T18:30:00.000Z",
        team0: { actual: 304, prod: 410 },
        team1: { actual: 308, prod: 290 },
        team2: { actual: 428, prod: 363 },
        team3: { actual: 325, prod: 402 },
        team4: { actual: 298, prod: 328 },
        team5: { actual: 413, prod: 272 },
        team6: { actual: 302, prod: 337 },
        team7: { actual: 438, prod: 335 },
        team8: { actual: 419, prod: 356 },
        team9: { actual: 345, prod: 262 },
    },
    {
        date: "2022-05-20T18:30:00.000Z",
        team0: { actual: 349, prod: 316 },
        team1: { actual: 381, prod: 324 },
        team2: { actual: 307, prod: 319 },
        team3: { actual: 362, prod: 399 },
        team4: { actual: 360, prod: 260 },
        team5: { actual: 433, prod: 424 },
        team6: { actual: 398, prod: 263 },
        team7: { actual: 414, prod: 365 },
        team8: { actual: 324, prod: 360 },
        team9: { actual: 307, prod: 412 },
    },
    {
        date: "2022-05-21T18:30:00.000Z",
        team0: { actual: 421, prod: 370 },
        team1: { actual: 390, prod: 326 },
        team2: { actual: 357, prod: 363 },
        team3: { actual: 429, prod: 295 },
        team4: { actual: 424, prod: 280 },
        team5: { actual: 328, prod: 348 },
        team6: { actual: 318, prod: 344 },
        team7: { actual: 321, prod: 425 },
        team8: { actual: 374, prod: 266 },
        team9: { actual: 408, prod: 389 },
    },
    {
        date: "2022-05-22T18:30:00.000Z",
        team0: { actual: 356, prod: 315 },
        team1: { actual: 409, prod: 420 },
        team2: { actual: 406, prod: 267 },
        team3: { actual: 370, prod: 263 },
        team4: { actual: 327, prod: 319 },
        team5: { actual: 391, prod: 259 },
        team6: { actual: 381, prod: 277 },
        team7: { actual: 346, prod: 407 },
        team8: { actual: 434, prod: 308 },
        team9: { actual: 416, prod: 391 },
    },
    {
        date: "2022-05-23T18:30:00.000Z",
        team0: { actual: 439, prod: 306 },
        team1: { actual: 435, prod: 268 },
        team2: { actual: 405, prod: 306 },
        team3: { actual: 364, prod: 376 },
        team4: { actual: 390, prod: 262 },
        team5: { actual: 365, prod: 390 },
        team6: { actual: 306, prod: 364 },
        team7: { actual: 434, prod: 392 },
        team8: { actual: 389, prod: 318 },
        team9: { actual: 361, prod: 281 },
    },
    {
        date: "2022-05-24T18:30:00.000Z",
        team0: { actual: 407, prod: 404 },
        team1: { actual: 302, prod: 365 },
        team2: { actual: 390, prod: 348 },
        team3: { actual: 412, prod: 387 },
        team4: { actual: 312, prod: 340 },
        team5: { actual: 322, prod: 385 },
        team6: { actual: 317, prod: 287 },
        team7: { actual: 401, prod: 415 },
        team8: { actual: 399, prod: 325 },
        team9: { actual: 418, prod: 398 },
    },
    {
        date: "2022-05-25T18:30:00.000Z",
        team0: { actual: 299, prod: 360 },
        team1: { actual: 354, prod: 291 },
        team2: { actual: 380, prod: 405 },
        team3: { actual: 365, prod: 263 },
        team4: { actual: 358, prod: 387 },
        team5: { actual: 325, prod: 357 },
        team6: { actual: 348, prod: 354 },
        team7: { actual: 438, prod: 357 },
        team8: { actual: 348, prod: 273 },
        team9: { actual: 420, prod: 337 },
    },
    {
        date: "2022-05-26T18:30:00.000Z",
        team0: { actual: 328, prod: 262 },
        team1: { actual: 398, prod: 364 },
        team2: { actual: 419, prod: 425 },
        team3: { actual: 349, prod: 263 },
        team4: { actual: 326, prod: 409 },
        team5: { actual: 329, prod: 417 },
        team6: { actual: 369, prod: 262 },
        team7: { actual: 294, prod: 410 },
        team8: { actual: 320, prod: 329 },
        team9: { actual: 379, prod: 410 },
    },
    {
        date: "2022-05-27T18:30:00.000Z",
        team0: { actual: 431, prod: 262 },
        team1: { actual: 354, prod: 342 },
        team2: { actual: 352, prod: 421 },
        team3: { actual: 292, prod: 264 },
        team4: { actual: 355, prod: 394 },
        team5: { actual: 384, prod: 326 },
        team6: { actual: 333, prod: 359 },
        team7: { actual: 414, prod: 309 },
        team8: { actual: 342, prod: 357 },
        team9: { actual: 300, prod: 327 },
    },
    {
        date: "2022-05-28T18:30:00.000Z",
        team0: { actual: 422, prod: 319 },
        team1: { actual: 355, prod: 336 },
        team2: { actual: 349, prod: 392 },
        team3: { actual: 377, prod: 358 },
        team4: { actual: 415, prod: 415 },
        team5: { actual: 376, prod: 294 },
        team6: { actual: 395, prod: 395 },
        team7: { actual: 430, prod: 308 },
        team8: { actual: 365, prod: 363 },
        team9: { actual: 323, prod: 261 },
    },
    {
        date: "2022-05-29T18:30:00.000Z",
        team0: { actual: 328, prod: 292 },
        team1: { actual: 367, prod: 282 },
        team2: { actual: 438, prod: 358 },
        team3: { actual: 359, prod: 277 },
        team4: { actual: 442, prod: 347 },
        team5: { actual: 303, prod: 364 },
        team6: { actual: 434, prod: 280 },
        team7: { actual: 342, prod: 295 },
        team8: { actual: 417, prod: 310 },
        team9: { actual: 412, prod: 368 },
    },
    {
        date: "2022-05-30T18:30:00.000Z",
        team0: { actual: 346, prod: 329 },
        team1: { actual: 328, prod: 316 },
        team2: { actual: 421, prod: 258 },
        team3: { actual: 352, prod: 333 },
        team4: { actual: 315, prod: 407 },
        team5: { actual: 427, prod: 350 },
        team6: { actual: 406, prod: 277 },
        team7: { actual: 380, prod: 308 },
        team8: { actual: 383, prod: 278 },
        team9: { actual: 345, prod: 307 },
    },
];
