export const colors = {
    var0: "#000000",
    var1: "#1D2B53",
    var2: "#7E2553",
    var3: "#008751",
    var4: "#AB5236",
    var5: "#5F574F",
    var6: "#C2C3C7",
    var7: "#FFF1E8",
    var8: "#FF004D",
    var9: "#FFA300",
    var10: "#FFEC27",
    var11: "#00E436",
    var12: "#29ADFF",
    var13: "#83769C",
    var14: "#FF77A8",
    var15: "#FFCCAA",
};
